import ax1 from '../media/avl.webp'
import dvm from '../media/dvom.jpg'
import svm from '../media/sevom.jpg'
import chrom from '../media/charom.avif'
import pnjom from '../media/pnjom.jpg'
import shish from '../media/shiiiiish.jpg'


function OneRadvin() {

 

  return (
<>
<section class="bg-white dark:bg-gray-900">
    <div class="py-20 px-4 mx-auto max-w-screen-xl lg:px-12 sm:text-center lg:py-16">
        <h2 class="mb-4 text-2xl lg:text-4xl text-center tracking-tight font-extrabold text-gray-900 dark:text-white"> آژانس گردشگری رادوین گشت آزاد </h2>
        <p class="font-light text-gray-500 text-center sm:text-lg md:px-20 lg:px-38 xl:px-48 dark:text-gray-400">
        
        
        رادوین گشت با ارائه خدمات گردشگری آنلاین پا به پات تا مقصد میاد. هر کجای دنیا و هر ساعت از شبانه‌روز که هست؛ بهمون زنگ بزن و توی چند دقیقه بلیط هواپیما، بلیط چارتر، هتل و تورهای مسافرتی و طبیعت‌گردی خودت رو رزرو کن.        </p>
        <div class="gap-4 mt-8 sm:grid sm:grid-cols-4 sm:mt-12">
            <img class=" object-cover col-span-2 mb-4 sm:mb-0  rounded-lg scale-100 ease-in duration-300 hover:scale-105 h-full w-full object-fill " src={ax1} alt="content gallery 1"/>
            <img class="hidden col-span-1 sm:block rounded-lg scale-100 ease-in duration-300 hover:scale-105 h-full w-full" src={dvm} alt="content gallery 2"/>
            <img class="hidden col-span-1 sm:block rounded-lg scale-100 ease-in duration-300 hover:scale-105 h-full w-full " src={svm} alt="content gallery 3"/>
            <img class="hidden col-span-1 sm:block rounded-lg scale-100 ease-in duration-300 hover:scale-105 h-full w-full" src= {chrom}  alt="content gallery 4"/>
            <img class="col-span-2  h-full w-full rounded-lg scale-100 ease-in duration-300 hover:scale-105 object-fill" src={pnjom} alt="content gallery 5"/>
            <img class="hidden  h-full w-full col-span-1 sm:block rounded-lg scale-100 ease-in duration-300 hover:scale-105" src={shish} alt="content gallery 6"/>
        </div>
    </div>
</section>


</>
   
  );
}

export default OneRadvin;
