import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react';
import './Base.css'
import Home from '../home/Home';
import Two from '../home/two/Two';
import Tree from '../home/tree/Tree';
import One from '../home/one/One';
import Table from '../home/table/Table';
import Four from '../home/four/Tozih';
import Tozih from '../home/four/Tozih';

import {Link} from 'react-router-dom';
import HomeRadvin from '../homeRadvin/HomeRadvin';
import { Button } from 'flowbite-react';




function Base() {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [theme, setTheme] = useState(null);



    useEffect(()=>{
      if(theme==="dark"){
      document.documentElement.classList.add("dark");
    }else{
      document.documentElement.classList.remove("dark");

    }
    },[theme]);


    const handleThemeSwitch=()=>{
      setTheme(theme==="dark"?"light":"dark");
    }

  return (
<>

<nav class=" fixed  z-50 w-full bg-white  border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
  <div class="px-3 py-3 lg:px-5 lg:pl-3 ">
  
    <div class="flex items-center justify-between">
      <div class="flex items-center justify-start rtl:justify-end">
     
        <button   onClick={() => setIsNavOpen((prev) => !prev)} data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar" aria-controls="logo-sidebar" type="button" class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
            <span class="sr-only">Open sidebar</span>
            
            <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
               <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
            </svg>
         </button>


         

        <a href="#" class="flex ms-2 md:me-24">
          <span class="self-center text-ls font-semibold lg:text-2xl whitespace-nowrap dark:text-white">رادوین گشت
          
          
          </span>

        </a>

        
      </div>
      <div class="">



      






      <button id="theme-toggle" type="button" class="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none  dark:focus:ring-gray-700 rounded-lg text-sm p-2.5" onClick={handleThemeSwitch}>
    <svg id="theme-toggle-dark-icon" class=" w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path></svg>
            </button>
      <a href="#" class=" text-sm font-large inline-block align-text-top 		text-gray-500 hover:underline dark:text-gray-400">021-88844790</a>
      
       
        <a href="tel:02188844790" class="inline-flex items-center p-2 text-sm font-medium text-primary-500 rounded-lg dark:text-gray-400 hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 dark:hover:bg-gray-600 focus:outline-none dark:focus:ring-gray-500">
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path></svg>
        </a>
        </div>
    </div>
  </div>
</nav>














<div   class="bg-gray-50  w-full dark:bg-gray-900  lg:pt-5" >


  <div class="max-w-screen-2xl mx-auto flex w-full h-full fixed top-10  ">
    <div className='bg-white dark:bg-gray-800 ' >
      <aside dir='ltr'  className={isNavOpen ? "showMenuNav" : "hideMenuNav"} id="sidebar" class="fixed top-10 left-0 lg:z-0 w-96 h-screen transition-transform -translate-x-full bg-white border-r border-gray-200 lg:translate-x-0 dark:bg-gray-800 dark:border-gray-700  lg:relative lg:right-0  lg:rounded-lg shadow-2xl " aria-label="Sidebar">
        
        

<div class=" w-full max-w-sm bg-white dark:bg-gray-800  ">
    <div class="flex justify-end px-4 pt-4 lg:pt-0 ">
       

        <div id="dropdown" class="z-10 hidden text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
            <ul class="py-2" aria-labelledby="dropdownButton">
            <li>
                <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Edit</a>
            </li>
            <li>
                <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Export Data</a>
            </li>
            <li>
                <a href="#" class="block px-4 py-2 text-sm text-red-600 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Delete</a>
            </li>
            </ul>
        </div>
    </div>
    <div class="flex flex-col items-center bg-white dark:bg-gray-800">
    <div id="alert-update" class="p-4 mb-3 m-2 rounded-lg  bg-primary-50 hover:bg-primary-100 dark:bg-primary-900" role="alert">
            <div class="  mb-3 ">
                <span class="bg-purple-100 text-purple-800 hover:bg-purple-200   font-medium mr-2 px-2.5 py-0.5 rounded ">اقساط</span>
                
            </div>
            <div class="mb-3 text-sm font-light text-right text-primary-700 dark:text-primary-300 ">
            در رادوین گشت امکان خرید اقساطی تقریبا برای تمامی تورها وجود دارد و شما هر کدام از تورها را که بخواهید می‌توانید از 4 تا 12 ماهه به صورت قسط بندی شده خریداری نمایید. شما تا سقف 100 میلیون تومان بدون نیاز به ضامن به ازای هر فرد که دارای دسته چک صیادی باشد، می‌توانید از طریق رادوین گشت تور، هتل یا بلیط هواپیما رزرو کنید                     </div>
            
            <a href="#" class="text-md font-medium text-right block underline text-teal-600 dark:text-teal-500 hover:no-underline ">

            شرایط اقساط            </a>
            </div>
        <div class="flex ">



          
        <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8 "/>
      <div class="text-center " dir="ltr">
         
      <ul class="flex justify-center mt-5 space-x-5">
              <li>


         






        <a href="https://t.me/Radvingashtagency"  class="inline-flex items-center p-2 text-sm font-medium text-primary-500 rounded-lg dark:text-primary-400 hover:bg-primary-50 focus:ring-4 focus:ring-primary-300 dark:hover:bg-primary-200 focus:outline-none dark:focus:ring-primary-500">
                  <span class="[&>svg]:h-7 [&>svg]:w-7 [&>svg]:fill-[#0088cc]">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">

                            <path
                            d="M248 8C111 8 0 119 0 256S111 504 248 504 496 393 496 256 385 8 248 8zM363 176.7c-3.7 39.2-19.9 134.4-28.1 178.3-3.5 18.6-10.3 24.8-16.9 25.4-14.4 1.3-25.3-9.5-39.3-18.7-21.8-14.3-34.2-23.2-55.3-37.2-24.5-16.1-8.6-25 5.3-39.5 3.7-3.8 67.1-61.5 68.3-66.7 .2-.7 .3-3.1-1.2-4.4s-3.6-.8-5.1-.5q-3.3 .7-104.6 69.1-14.8 10.2-26.9 9.9c-8.9-.2-25.9-5-38.6-9.1-15.5-5-27.9-7.7-26.8-16.3q.8-6.7 18.5-13.7 108.4-47.2 144.6-62.3c68.9-28.6 83.2-33.6 92.5-33.8 2.1 0 6.6 .5 9.6 2.9a10.5 10.5 0 0 1 3.5 6.7A43.8 43.8 0 0 1 363 176.7z" />
                        </svg>
                        </span>    


                                </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/radvingashtagency" class="inline-flex items-center p-2 text-sm font-medium  rounded-lg dark:text-pink-400 hover:bg-pink-50 focus:ring-4 focus:ring-pink-300 dark:hover:bg-pink-200 focus:outline-none dark:focus:ring-pink-500">
                                            
                                        <span class="[&>svg]:h-7 [&>svg]:w-7 [&>svg]:fill-[#c13584]  ">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path
                            d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                        </svg>
                        </span>                
                        </a>
                                    </li>

                                    <li>
                                        <a href="https://wa.me/09370218231" class="inline-flex items-center p-2 text-sm font-medium text-emerald-500 rounded-lg dark:text-emerald-400 hover:bg-emerald-50 focus:ring-4 focus:ring-emerald-300 dark:hover:bg-emerald-200 focus:outline-none dark:focus:ring-emerald-500">
                                            
                                        <span class="[&>svg]:h-7 [&>svg]:w-7 [&>svg]:fill-[#128C7E]">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 448 512">
                            <path
                            d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
                        </svg>
                        </span>              
                        </a>
              </li>
              
              
          </ul>
      </div> </div>
    </div>
</div>

        
        <div class="overflow-y-auto pt-5 px-3 h-full bg-white dark:bg-gray-800 lg:rounded-lg" dir='rtl'>
        
          
          <div class="my-5  mt-0 border-gray-100 dark:border-gray-700">
            
        
            
            <div className='border  border-gray-300 dark:border-gray-600 rounded-xl p-1'>
            <li className='list-none m-1 p-1'>
              <a href="#" class="flex items-center p-2 text-base font-medium rounded-lg dark:text-white bg-purple-50 hover:bg-purple-100 dark:bg-purple-900 dark:hover:bg-purple-800 group">
                 
              <a href="tel:02188844790" class="inline-flex items-center p-2 text-sm font-medium text-green-500 rounded-lg dark:text-gray-400 hover:bg-primary-50 focus:ring-4 focus:ring-gray-300 dark:hover:bg-primary-600 ml-3 focus:outline-none dark:focus:ring-gray-500">
                  <svg class="w-5 h-5 dark:text-primary-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path></svg>
              </a>

                


                <span class="ml-3 text-sm  text-purple-600 dark:text-purple-300 font-medium">
                ۰۲۱۸۸۸۴۴۷۹۰   
                
                </span>
              </a>
            </li>



            <li className='list-none m-1 p-1 pt-0'>
              <a href="#" class="flex items-center p-2 text-base font-medium  rounded-lg bg-primary-50 hover:bg-primary-100 dark:bg-primary-900 dark:hover:bg-primary-800 group">
                <a href="tel:09370218231" class="inline-flex items-center p-2 text-sm font-medium text-green-500 rounded-lg dark:text-gray-400 hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 dark:hover:bg-primary-600 ml-3 focus:outline-none dark:focus:ring-gray-500">
                  <svg class="w-5 h-5 dark:text-primary-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path></svg>
              </a>

                


                <span class="ml-3 text-sm  text-purple-600 dark:text-purple-300 font-medium">
                ۰۹۳۷۰۲۱۸۲۳۱   
                
                </span></a>
            </li>
            </div>
            
          </div>
         
          <ul class="space-y-2" >
            
            <li>
              <a  class="flex items-center p-2 text-base font-medium text-purple-900 rounded-lg hover:bg-purple-100 dark:text-white shadow-inner    dark:hover:bg-purple-700 group">
                <svg class="w-6 h-6 text-purple-600 transition duration-75 dark:text-purple-400 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path clip-rule="evenodd" fill-rule="evenodd" d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11 4a1 1 0 10-2 0v4a1 1 0 102 0V7zm-3 1a1 1 0 10-2 0v3a1 1 0 102 0V8zM8 9a1 1 0 00-2 0v2a1 1 0 102 0V9z"></path>
                </svg>
                <span class="mr-3 ">تور  </span>
              </a>
            </li>
            <li>
              <a   class="flex items-center p-2 text-base font-medium text-primary-900 hover:bg-primary-100 rounded-lg dark:text-white shadow-inner   dark:hover:bg-primary-700 group">
                <svg class="w-6 h-6 text-primary-600 transition duration-75 dark:text-primary-400 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path clip-rule="evenodd" fill-rule="evenodd" d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11 4a1 1 0 10-2 0v4a1 1 0 102 0V7zm-3 1a1 1 0 10-2 0v3a1 1 0 102 0V8zM8 9a1 1 0 00-2 0v2a1 1 0 102 0V9z"></path>
                </svg>
                <span class="mr-3"> ویزا </span>

                
              </a>
            </li>
            <li>
              <a  class="flex items-center p-2 text-base font-medium text-purple-900 rounded-lg hover:bg-purple-100 dark:text-white shadow-inner  dark:hover:bg-purple-700 group">
                <svg class="w-6 h-6 text-purple-600 transition duration-75 dark:text-purple-400 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path clip-rule="evenodd" fill-rule="evenodd" d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11 4a1 1 0 10-2 0v4a1 1 0 102 0V7zm-3 1a1 1 0 10-2 0v3a1 1 0 102 0V8zM8 9a1 1 0 00-2 0v2a1 1 0 102 0V9z"></path>
                </svg>
                <span class="mr-3 ">هتل</span>
              </a>
            </li>
            <li>
              <a  class="flex items-center p-2 text-base font-medium text-primary-900 hover:bg-primary-100 rounded-lg dark:text-white shadow-inner   dark:hover:bg-primary-700 group">
                <svg class="w-6 h-6 text-primary-600 transition duration-75 dark:text-primary-400 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path clip-rule="evenodd" fill-rule="evenodd" d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11 4a1 1 0 10-2 0v4a1 1 0 102 0V7zm-3 1a1 1 0 10-2 0v3a1 1 0 102 0V8zM8 9a1 1 0 00-2 0v2a1 1 0 102 0V9z"></path>
                </svg>
                <span class="mr-3">پرواز داخلی</span>

                
              </a>
            </li>
            <li>
              <a  class="flex items-center p-2 text-base font-medium text-purple-900 hover:bg-purple-100 rounded-lg dark:text-white shadow-inner   dark:hover:bg-purple-700 group">
                <svg class="w-6 h-6 text-purple-600 transition duration-75 dark:text-purple-400 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path clip-rule="evenodd" fill-rule="evenodd" d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11 4a1 1 0 10-2 0v4a1 1 0 102 0V7zm-3 1a1 1 0 10-2 0v3a1 1 0 102 0V8zM8 9a1 1 0 00-2 0v2a1 1 0 102 0V9z"></path>
                </svg>
                <span class="mr-3">پرواز خارجی</span>

                
              </a>
            </li>
            
            
          </ul>
          <ul class="pt-5 mt-5 space-y-2 border-t border-gray-200 dark:border-gray-700">
           
            
           
           
          </ul>
        </div>
      </aside>
    </div>

    <main class="flex-1 pb-4 h-full overflow-y-auto lg:pl-4 px-1 lg:px-12 bg-white dark:bg-gray-900">
      <div class="grid grid-cols-1 gap-1 mb-4">
        <HomeRadvin/>
      </div>
     
    </main>
  </div>
</div>

</>
   
  );

  
}

export default Base;
