import { useState } from "react";


function Accor() {
  const [accorOpen,setaccOpen]=useState(false);
  const [accorOpen2,setaccOpen2]=useState(false);
  const [accorOpen3,setaccOpen3]=useState(false);
  const [accorOpen4,setaccOpen4]=useState(false);
  const [accorOpen5,setaccOpen5]=useState(false);
  const [accorOpen6,setaccOpen6]=useState(false);


 

  return (
<>

<section class="bg-white dark:bg-gray-900">
    <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6 ">
        <h2 class="mb-6 lg:mb-8 text-3xl lg:text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">سوال هایی که ممکن است داشته باشید
</h2>
        <div class="mx-auto max-w-screen-md">
            <div id="accordion-flush" data-accordion="collapse" data-active-classes="bg-white dark:bg-gray-900 text-gray-900 dark:text-white" data-inactive-classes="text-gray-500 dark:text-gray-400">
                
                
                
                <h2 id="accordion-flush-heading-1">
                    <button type="button" onClick={()=>setaccOpen(!accorOpen)} 
                    class="flex justify-between items-center py-5 w-full font-medium text-right text-gray-900 bg-white border-b border-gray-200 dark:border-gray-700 dark:bg-gray-900 dark:text-white" data-accordion-target="#accordion-flush-body-1" aria-expanded="true" aria-controls="accordion-flush-body-1">
                        <span >
                        میزان بار مجاز برای هر بلیط چقدر هست؟          
                                        </span>
                        <svg data-accordion-icon="" class="w-6 h-6 rotate-180 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>

                    </button>
                </h2>


                <div id="accordion-flush-body-1" class="" aria-labelledby="accordion-flush-heading-1">
                    <div className={`py-5 border-b  border-gray-200 dark:border-gray-700 transition-all ease-in-out  overflow-hidden
                     ${accorOpen ? 'grid-rows-[1fr] opacity-100 active ':'grid-rows-[0fr] opacity-0 hidden'}`}>
                         <p class="mb-2 text-gray-500 dark:text-gray-400">
                         میزان بار مجاز برای هر بلیط هواپیما بستگی به شرکت هواپیمایی و نوع بلیط دارد. هر شرکت هواپیمایی می‌تواند محدودیت‌های متفاوتی در مورد وزن و تعداد بار مجاز برای هر بلیط داشته باشد. همچنین، میزان بار مجاز ممکن است برای پروازهای داخلی و بین‌المللی نیز متفاوت باشد. بعضی از شرکت‌های هواپیمایی به مسافران اجازه می‌دهند تا یک یا دو قطعه بار با وزن حداکثر 23 کیلوگرم را به همراه خود ببرند، در حالی که برخی دیگر اجازه می‌دهند تا تعداد بیشتری از بار را با وزن کمتری با خود ببرند. همچنین، برخی شرکت‌های هواپیمایی به مسافران اجازه می‌دهند تا بار اضافی را با پرداخت هزینه اضافی به همراه خود ببرند. بهتر است قبل از خرید بلیط هواپیما، شرایط بار مجاز هر شرکت هواپیمایی را بررسی کنید و در صورت نیاز، با شرکت هواپیمایی تماس بگیرید تا اطلاعات دقیق‌تری در این زمینه دریافت کنید.                          
                          </p>
                    </div>
                </div>








                
                <h2 id="accordion-flush-heading-2">
                    <button type="button"
                    onClick={()=>setaccOpen2(!accorOpen2)} 
                     class="flex justify-between items-center py-5 w-full font-medium text-right text-gray-900 bg-white border-b border-gray-200 dark:border-gray-700 dark:bg-gray-900 dark:text-white" data-accordion-target="#accordion-flush-body-2" aria-expanded="false" aria-controls="accordion-flush-body-2">
                        <span>
                        جریمه کنسلی بلیط هواپیما چگونه محاسبه می شود؟                          
                          </span>
                        <svg data-accordion-icon="" class="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    </button>
                </h2>
                <div id="accordion-flush-body-2" class="" aria-labelledby="accordion-flush-heading-2">
                <div className={`py-5 border-b  border-gray-200 dark:border-gray-700 transition-all ease-in-out  overflow-hidden
                     ${accorOpen2 ? 'grid-rows-[1fr] opacity-100 active ':'grid-rows-[0fr] opacity-0 hidden'}`}>
                        <p class="mb-2 text-gray-500 dark:text-gray-400">
                        جریمه کنسلی بلیط هواپیما ممکن است بسته به شرایط و قوانین هر شرکت هواپیمایی متفاوت باشد. اما در بسیاری از موارد، جریمه کنسلی بلیط هواپیما به میزانی که با توجه به زمان کنسلی بلیط و همچنین نوع بلیط تعیین می شود، محاسبه می شود. در بسیاری از شرکت های هواپیمایی، بلیط های ارزان قیمت یا بلیط هایی که با شرایط خاصی خریداری شده اند، قابل کنسلی نیستند و در صورت کنسلی، هزینه بلیط به صورت کامل از دست می رود. در صورتی که بلیط قابل کنسلی باشد، هزینه جریمه کنسلی ممکن است تا 100 درصد ارزش بلیط باشد. برای مثال، در برخی شرکت های هواپیمایی، اگر بلیط را ۲۴ ساعت قبل از پرواز کنسل کنید، ممکن است تا ۵۰ درصد ارزش بلیط به عنوان جریمه کنسلی دریافت شود.                          
                          </p>
                    </div>
                </div>



                <h2 id="accordion-flush-heading-3">
                    <button type="button"
                    onClick={()=>setaccOpen3(!accorOpen3)} 
                     class="flex justify-between items-center py-5 w-full font-medium text-right text-gray-900 bg-white border-b border-gray-200 dark:border-gray-700 dark:bg-gray-900 dark:text-white" data-accordion-target="#accordion-flush-body-3" aria-expanded="false" aria-controls="accordion-flush-body-3">
                        <span>
                        تفاوت کلاس های پروازی مختلف در چیست؟

                          </span>
                        <svg data-accordion-icon="" class="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    </button>
                </h2>
                <div id="accordion-flush-body-3" class="" aria-labelledby="accordion-flush-heading-3">
                <div className={`py-5 border-b  border-gray-200 dark:border-gray-700 transition-all ease-in-out  overflow-hidden
                     ${accorOpen3 ? 'grid-rows-[1fr] opacity-100 active ':'grid-rows-[0fr] opacity-0 hidden'}`}>
                        <p class="mb-2 text-gray-500 dark:text-gray-400">
                        کلاس‌های پروازی مختلف برای ارائه خدمات متفاوت به مسافران طراحی شده‌اند. این کلاس‌ها شامل کلاس اقتصادی، کلاس بیزینس و کلاس اول می‌شوند. تفاوت‌های اصلی بین این کلاس‌ها عبارتند از: 1. فضای صندلی: در کلاس اقتصادی، فضای صندلی‌ها کوچکتر و فاصله بین آن‌ها کمتر است، در حالی که در کلاس بیزینس و کلاس اول، فضای صندلی‌ها بزرگتر و فاصله بین آن‌ها بیشتر است. 2. خدمات: در کلاس اقتصادی، خدمات محدودتر و ساده‌تر هستند، در حالی که در کلاس بیزینس و کلاس اول، خدمات بیشتر و با کیفیت بالاتری ارائه می‌شود. به عنوان مثال، در کلاس بیزینس و کلاس اول، غذاهای با کیفیت بالاتر و نوشیدنی‌های الکلی رایگان ارائه می‌شود. 3. قیمت: قیمت بلیط در کلاس اول و بیزینس بیشتر از کلاس اقتصادی است، به دلیل ارائه خدمات بیشتر و فضای صندلی بزرگتر. در کل، کلاس پروازی مورد نظر شما بستگی به نیازها و بودجه شما دارد. اگر برای سفر کردن به مقصد خود نیاز به فضای صندلی بزرگتر و خدمات بهتر دارید، می‌توانید برای کلاس بیزینس یا کلاس اول انتخاب کنید. اما اگر بودجه شما محدود است، می‌توانید برای کلاس اقتصادی انتخاب کنید.


                          </p>
                    </div>
                </div>

                <h2 id="accordion-flush-heading-4">
                    <button type="button"
                    onClick={()=>setaccOpen4(!accorOpen4)} 
                     class="flex justify-between items-center py-5 w-full font-medium text-right text-gray-900 bg-white border-b border-gray-200 dark:border-gray-700 dark:bg-gray-900 dark:text-white" data-accordion-target="#accordion-flush-body-3" aria-expanded="false" aria-controls="accordion-flush-body-3">
                        <span>
                        صدور ویزای توریستی چند روز طول می کشد؟
                          </span>
                        <svg data-accordion-icon="" class="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    </button>
                </h2>
                <div id="accordion-flush-body-3" class="" aria-labelledby="accordion-flush-heading-3">
                <div className={`py-5 border-b  border-gray-200 dark:border-gray-700 transition-all ease-in-out  overflow-hidden
                     ${accorOpen4 ? 'grid-rows-[1fr] opacity-100 active ':'grid-rows-[0fr] opacity-0 hidden'}`}>
                        <p class="mb-2 text-gray-500 dark:text-gray-400">

                        بسته به مقصد شما، ممکن است صدور ویزای توریستی از یک روز کاری الی دو ماه طول بکشد.

                          </p>
                    </div>
                </div>




                <h2 id="accordion-flush-heading-5">
                    <button type="button"
                    onClick={()=>setaccOpen5(!accorOpen5)} 
                     class="flex justify-between items-center py-5 w-full font-medium text-right text-gray-900 bg-white border-b border-gray-200 dark:border-gray-700 dark:bg-gray-900 dark:text-white" data-accordion-target="#accordion-flush-body-3" aria-expanded="false" aria-controls="accordion-flush-body-3">
                        <span>
                        چه مدت قبل از سفر، تور را رزرو کنیم؟


                          </span>
                        <svg data-accordion-icon="" class="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    </button>
                </h2>
                <div id="accordion-flush-body-3" class="" aria-labelledby="accordion-flush-heading-3">
                <div className={`py-5 border-b  border-gray-200 dark:border-gray-700 transition-all ease-in-out  overflow-hidden
                     ${accorOpen5 ? 'grid-rows-[1fr] opacity-100 active ':'grid-rows-[0fr] opacity-0 hidden'}`}>
                        <p class="mb-2 text-gray-500 dark:text-gray-400">

                        با توجه به پروسه زمانی صدور ویزا، بیمه نامه مسافرتی و با توجه به تخفیفات آژانس های مسافرتی و گردشگری، بهتر است از سه الی یک ماه قبل از تاریخ سفر، رزرو تور صورت گیرد.
                          </p>
                    </div>
                </div>





                <h2 id="accordion-flush-heading-6">
                    <button type="button"
                    onClick={()=>setaccOpen6(!accorOpen6)} 
                     class="flex justify-between items-center py-5 w-full font-medium text-right text-gray-900 bg-white border-b border-gray-200 dark:border-gray-700 dark:bg-gray-900 dark:text-white" data-accordion-target="#accordion-flush-body-3" aria-expanded="false" aria-controls="accordion-flush-body-3">
                        <span>
                        بعد از یک بار ریجکت شدن ویزا، آیا احتمال درخواست مجدد و یا رفع ریجکتی وجود دارد؟

                          </span>
                        <svg data-accordion-icon="" class="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    </button>
                </h2>
                <div id="accordion-flush-body-3" class="" aria-labelledby="accordion-flush-heading-3">
                <div className={`py-5 border-b  border-gray-200 dark:border-gray-700 transition-all ease-in-out  overflow-hidden
                     ${accorOpen6 ? 'grid-rows-[1fr] opacity-100 active ':'grid-rows-[0fr] opacity-0 hidden'}`}>
                        <p class="mb-2 text-gray-500 dark:text-gray-400">


                        بله، شما هر زمان می توانید از طریق کارشناسان قصران گشت برای رفع ریجکتی و یا درخواست مجدد ویزا اقدام نمایید.


                          </p>
                    </div>
                </div>









               
            </div> 
        </div>               
    </div>
</section>

</>
   
  );
}

export default Accor;