import logo from './logo.svg';
import './App.css';
import { Route,Routes } from 'react-router-dom';

import Count from './classes/count/Count';
import Base from './classes/base/Base';
import Tozih from './classes/home/four/Tozih';
import Safeasli from './classes/safeasli/Safeasli';
function App() {
  return (
    <>






    <div>
    <Routes>        
      <Route path="/"  element={<Base/>} />
    
    </Routes>
    </div>
    </>
  );
}

export default App;




