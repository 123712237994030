import FiveRadvin from "./fiveRadvin/FiveRadvin";
import FourRadvin from "./fourRadvin/FourRadvin";
import OneRadvin from "./oneRadvin/OneRadvin";
import TreeRadvin from "./treeRadvin/TreeRadvin";
import TwoRadvin from "./twoRadvin/TwoRadvin";



function HomeRadvin() {

 

  return (
<>

<OneRadvin/>
<TwoRadvin/>
<TreeRadvin/>
<FourRadvin/>
<FiveRadvin/>


</>
   
  );
}

export default HomeRadvin;
