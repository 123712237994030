import React from 'react'
import Accor from './Accor'





function FourRadvin  ()  {
  return (


<>

<Accor/>

</>

);
}

export default FourRadvin;