import { Carousel } from "flowbite-react";
import axyek from '../media/axetour1.jpeg'
import axdo from '../media/axetour2.jpg'
import axse from '../media/axese.jpg'


function TreeRadvin() {

 

    return (
  <>
  
  
  
  
                           
                            
                            
                            
  
  <section class="bg-white dark:bg-gray-900">
  <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6" dir="ltr">
      <div class="grid space-y-8 lg:grid-cols-2 lg:gap-12 lg:space-y-0">
          <div>
              <p class="mb-4 text-gray-500 sm:text-xl text-right dark:text-gray-400">سفر کردن یکی از بهترین تجربیات است که می‌توانیم در طول زندگی داشته باشیم. با سفر کردن می‌توانیم با آدم‌های جدید، فرهنگ‌ها و آداب رسوم نو، غذاهای جدید، اقلیم‌ها ی مختلف آشنا شویم و درک بیشتری نسبت‌به خودمان و زندگی کسب کنیم. روش‌های مختلفی برای سفر کردن وجود دارد. می‌توانیم با ماشین شخصی خود به هر کجا که می‌خواهیم برویم، در طبیعت چادر بزنیم یا به خانه اقوام و آشنایانمان برویم. اما اگر بخواهیم به یک مقصد جدید برویم یا یک جاذبه طبیعی را از نزدیک ببینیم یا به خارج از کشور برویم تا با جاذبه‌های گردشگری کشورهای دیگر آشنا شویم، بهترین راه خرید تور مسافرتی است.</p>
              
          </div>
          <div>
          <h2 class="mb-4 text-4xl tracking-tight text-right font-extrabold text-gray-900 dark:text-white">چرا رادوین گشت ؟</h2>

              <a href="#" class="flex justify-between items-center p-4 mb-6 bg-white rounded-lg border-l-8 shadow dark:bg-gray-800 dark:hover:bg-gray-700 border-primary-600 dark:border-primary-500 hover:bg-gray-50">
                  <div>
                      <span class="block mb-1 text-xs font-medium text-gray-500 uppercase dark:text-gray-400">سفر به شگفت انگیزترین مکان‌ها بدون خرید تور امکان پذیر نیست

</span>
                      <span class="text-xl font-semibold text-primary-600 dark:text-primary-500">بیش از ۵۰۰ تور به سراسر جهان  </span>
                  </div>
                  <svg class="w-6 h-6 text-primary-600 dark:text-primary-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
              </a>
              <a href="#" class="flex justify-between items-center p-4 mb-6 bg-white rounded-lg border-l-8 border-purple-600 shadow dark:bg-gray-800 dark:hover:bg-gray-700 dark:border-purple-500 hover:bg-gray-50">
                  <div>
                      <span class="block mb-1 text-xs font-medium text-gray-500 uppercase dark:text-gray-400">کیفیت را فدای قیمت نکرده‌ایم فقط با کمترین نرخ ممکن می‌فروشیم</span>
                      <span class="text-xl font-semibold text-purple-600 dark:text-purple-500">بهترین گارانتی قیمت تور</span>
                  </div>
                  <svg class="w-6 h-6 text-purple-600 dark:text-purple-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
              </a>
              <a href="#" class="flex justify-between items-center p-4 bg-white rounded-lg border-l-8 border-teal-600 shadow dark:bg-gray-800 dark:hover:bg-gray-700 dark:border-teal-500 hover:bg-gray-50">
                  <div>
                      <span class="block mb-1 text-xs font-medium text-gray-500 uppercase dark:text-gray-400">فقط کافیست در هر ساعت از شبانه روز با شماره 09370218231 تماس بگیرید</span>
                      <span class="text-xl font-semibold text-teal-600 dark:text-teal-500">پشتیبانی 24 ساعته از تور</span>
                  </div>
                  <svg class="w-6 h-6 text-teal-600 dark:text-teal-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
              </a>
          </div>
      </div>
  </div>
</section>
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  <section class="bg-white dark:bg-gray-900">
  <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
      <div class="mx-auto max-w-screen-sm text-center mb-8 lg:mb-16">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">تورها</h2>
          <p class="font-light text-gray-500 sm:text-xl dark:text-gray-400">
            
          آژانس مسافرتی رادوین گشت مجری انواع تورها با سالها تجربه در زمینه اخذ ویزای معتبر در تمام کشورهای مقصد، این اطمینان را به شما می دهد تا آسوده خاطر یک سفر به یادماندنی را تجربه کنید.            
            </p>
      </div> 
      <div class="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
          <article class="p-4 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
              <a href="#">
                  <img class="mb-5 rounded-lg" src={axyek} alt="office laptop working"/>
              </a>
              <span class="bg-purple-100 text-purple-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-purple-200 dark:text-purple-900"> مرداد و شهریور ماه </span>
              <h2 class="my-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                  <a href="#">تور 8 روزه روسیه <br/>(هتل 4 ستاره)</a>
              </h2>
              <p class="mb-4 font-light text-gray-500 dark:text-gray-400">  
              وسیله حمل و نقل : ایرلاین ایران ایر تور <br/>
              وعده‌های غذایی : تمام صبحانه‌ها و وعده ناهار <br/>
              مدت زمان برنامه : 8 روز و 7 شب
              
              
              
              
              
              
              </p>
             
          </article>
          <article class="p-4 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
              <a href="#">
                  <img class="mb-5 rounded-lg" src={axdo} alt="Google HQ"/>
              </a>
              <span class="bg-purple-100 text-purple-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-purple-200 dark:text-purple-900">مهرماه</span>
              <h2 class="my-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                  <a href="#">تور 11 روزه چین (شانگهای+هانگژو+پکن)</a>
              </h2>
              <p class="mb-4 font-light text-gray-500 dark:text-gray-400">

              هتل 5 ستاره <br/>
              وسیله حمل و نقل : هواپیما(ایرلاین ماهان) <br/>
              وعده‌های غذایی : ۱۰ وعده صبحانه و ۴ وعده غذا <br/>
              مدت زمان برنامه : 11 روز و 10 شب





              </p>
            
          </article>
          <article class="p-4 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
              <a href="#">
                  <img class="mb-5 rounded-lg" src={axse} alt="office laptops"/>
              </a>
              <span class="bg-purple-100 text-purple-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-purple-200 dark:text-purple-900">شهریور ماه</span>
              <h2 class="my-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                  <a href="#">تور وان ترکیه</a>
              </h2>
              <p class="mb-4 font-light text-gray-500 dark:text-gray-400">
                
              اقامت : سه شب اقامت در هتل 4 ستاره رسمینا <br/>
              وسیله حمل و نقل : اتوبوس و ون VIP <br/>
              وعده‌های غذایی : 3 وعده‌ صبحانه <br/>
              مدت زمان برنامه : 5 روز و 4 شب








              </p>
              
          </article>
      </div>  
  </div>
</section>
  
  </>
     
    );
  }
  
  export default TreeRadvin;
  