
import shomal from '../media/vasat.MOV';
import moghol from '../media/darya.MP4';
import chine from '../media/shab.MP4';


function TwoRadvin() {

 

  return (
<>
<section class="bg-white dark:bg-gray-900" >
  <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
      <div class="text-center text-gray-900">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 lg:text-5xl dark:text-white">تورهای ویژه تابستان ۱۴۰۳
</h2>
          <a href="#" class="inline-flex items-center text-lg font-medium text-primary-600 hover:text-primary-800 dark:text-primary-500 dark:hover:text-primary-700">
              برای رزرو و هماهنگی باهامون تماس بگیرین
              <svg class="ml-1 w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
          </a>
      </div>
      <div class="grid gap-6 mt-12 lg:mt-14 lg:gap-12 md:grid-cols-3">
          <div class="flex mb-2 md:flex-col md:mb-0 ">
          <video className="ml-4 w-auto h-36 md:w-full md:h-auto rounded-lg" 
        poster="https://www.smartcontentcreator.com/images/features/Templates.png" loop autoPlay playsinline muted>
            <source src={moghol} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          
          
                        <div>
                  <h3 class="text-xl font-bold md:mt-4 mb-2.5 text-gray-900 dark:text-white  text-center">
                  رزرو بلیط هواپیما
                    </h3>
                  <p class="text-gray-500 dark:text-gray-400 text-center">
                  آژانس گردشگری رادوین گشت از ابتدای فعالیت، نماینده فروش رسمی ایرلاین‌های معتبر داخلی و خارجی بوده است.

                    </p>
              </div>
          </div>
          <div class="flex mb-2 md:flex-col md:mb-0 ">

          <video className="ml-4 w-auto h-36 md:w-full md:h-auto rounded-lg" 
        poster="https://www.smartcontentcreator.com/images/features/Templates.png" loop autoPlay playsinline muted>
            <source src={shomal} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
                        <div>
                  <h3 class="text-xl font-bold md:mt-4 mb-2.5 text-gray-900 text-center dark:text-white">
                    
                  رزرو هتل
                    
                    </h3>
                  <p class="text-gray-500 dark:text-gray-400 text-center">
                  ما در رادوین گشت به مهمترین سامانه‌های رزرواسیون هتل در سرتاسر جهان دسترسی داریم و قادریم بهترین هتل‌ها را در گوشه و کنار دنیا برای شما رزرو کنیم.

                    </p>
              </div>
          </div>
          <div class="flex md:flex-col">
          <video className="ml-4 w-auto h-36 md:w-full md:h-auto rounded-lg" 
        poster="https://www.smartcontentcreator.com/images/features/Templates.png" loop autoPlay playsinline muted>
            <source src={chine} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          
                        <div>
                  <h3 class="text-xl font-bold md:mt-4 mb-2.5 text-gray-900 dark:text-white text-center">
                  ویزای گردشگری
                    
                    </h3>
                  <p class="text-gray-500 dark:text-gray-400 text-center">
                  آژانس رادوین گشت به دلیل رابطه طولانی مدت و قوی با معتبرترین سفارت‌های فعال در ایران، به عنوان آژانس معتمد و برگزیده سفارت‌های مطرح تبدیل شده است.                    </p>
              </div>
          </div>
      </div>
  </div>
</section>


</>
   
  );
}

export default TwoRadvin;
